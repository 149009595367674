import HotkeysListener, { Hotkeys, HotkeysListenerData, IKeyData, OperationType } from '@/types/Hotkeys'
import axios from 'axios'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const DEFAULT_HOTKEYS: Hotkeys = {
  createRequirement: [
    { code: 'AltLeft', key: 'Alt' },
    { code: 'KeyQ', key: 'q' }
  ],
  createTask: [
    { code: 'AltLeft', key: 'Alt' },
    { code: 'KeyT', key: 't' }
  ],
  deleteRequirement: [
    { code: 'AltLeft', key: 'Alt' },
    { code: 'KeyX', key: 'x' }
  ],
  deleteTask: [
    { code: 'ShiftLeft', key: 'Shift' },
    { code: 'KeyX', key: 'x' }
  ]
}

export default defineStore('hotkeys', () => {
  const hotkeys = ref<Hotkeys>(DEFAULT_HOTKEYS)
  const isHotkeysModalOpen = ref<boolean>(false)
  const listenersList = ref<HotkeysListenerData[]>([])

  const hotkeysListener = computed<HotkeysListener>(() => new HotkeysListener(hotkeys.value))

  async function fetchHotkeys () {
    try {
      const data: Hotkeys = await axios.get('/profile/').then(r => r.data.hotkeys)
      const entries = Object.entries(data) as [OperationType, IKeyData[]][]
      if (entries.length > 0) {
        hotkeys.value = entries
          .map<Hotkeys>(([type, value]) => ({ [type]: (value.length ? value : DEFAULT_HOTKEYS[type]) } as any))
          .reduce<Hotkeys>((object, value) => ({ ...object, ...value }), {} as Hotkeys)
      } else {
        hotkeys.value = DEFAULT_HOTKEYS
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function updateHotkeys (hotkeys: Hotkeys) {
    try {
      await axios.patch('/profile/', { hotkeys })
    } catch (error) {
      console.error(error)
    }
  }

  function listenHotkeys (listeners: HotkeysListenerData[]) {
    return hotkeysListener.value.setListener(listeners)
  }

  watch(isHotkeysModalOpen, value => {
    hotkeysListener.value.needStopListener = value
  })

  return {
    fetchHotkeys,
    updateHotkeys,
    listenHotkeys,

    hotkeys,
    isHotkeysModalOpen,
    listenersList
  }
})
